import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    siteBlocker: false,
    dev: false,
    debug: false,
    language: null,
    languages: null,
    labels: undefined,
    api: undefined,
    env: 'production',
    user: undefined,
    userID: 'guest',
    signedIn: false,
    appVisible: true,
    notifications: [],
    width: null,
    height: null,
    pathAfterLogin: null,
    inactivity: 0,
    time_zone: null,
    hasherSalt: "Temporary Sald",
    exposeLabels: false,
    disclaimer: JSON.parse(sessionStorage.getItem('disclaimer')) || "ON",
    zoomIntegrationTyp: "manual" //"api-call-srv-to-srv"
  },
  getters: {
    disclaimer: function(state){
      return state.disclaimer
    },
    debug: function(state){
      return state.debug
    },
    language: function(state){
      return state.language
    },
    languages: function(state){
      return state.languages
    },
    labels: function(state){
      return state.labels
    },
    api: function(state){
      return state.api
    },
    env: function(state){
      return state.env
    },
    user: function(state){
      return state.user
    },
    userID: function(state){
      return state.userID
    },
    signedIn: function(state){
      return state.signedIn
    },
    appVisible: function(state){
      return state.appVisible
    },
    notifications: function(state){
      return state.notifications
    },
    width: function(state){
      return state.width
    },
    height: function(state){
      return state.height
    },
    inactivity: function(state){
      return state.inactivity
    },
    siteBlocker: function(state){
      return state.siteBlocker
    },
    time_zone: function(state){
      return state.time_zone
    },
    hasherSalt: function(state){
      return state.hasherSalt
    },
    exposeLabels: function(state){
      return state.exposeLabels
    },
    zoomIntegrationTyp: function(state){
        return state.zoomIntegrationTyp
    }
  },
  actions: {
    disclaimer: function(context, payload){
        context.commit('disclaimer',payload)
    },
    debug: function(context, payload){
      context.commit('debug',payload)
    },
    language: function(context, payload){
      context.commit('language',payload)
    },
    languages: function(context, payload){
      context.commit('languages',payload)
    },
    labels: function(context, payload){
      context.commit('labels',payload)
    },
    api: function(context, payload){
      context.commit('api',payload)
    },
    tiny_api_key: function(context, payload){
      context.commit('tiny_api_key',payload)
    },
    env: function(context, payload){
      context.commit('env',payload)
    },
    user: function(context, payload){
      context.commit('user',payload)
    },
    appVisible: function(context, payload){
      context.commit('appVisible',payload)
    },
    notifications: function(context, payload){
      context.commit('notifications',payload)
    },
    width: function(context, payload){
      context.commit('width',payload)
    },
    height: function(context, payload){
      context.commit('height',payload)
    },
    inactivity: function(context, payload){
      context.commit('inactivity',payload)
    },
    siteBlocker: function(context, payload){
      context.commit('siteBlocker',payload)
    },
    time_zone: function(context, payload){
      context.commit('time_zone',payload)
    },
    exposeLabels: function(context, payload){
      context.commit('exposeLabels',payload)
    },
    zoomIntegrationTyp: function(context, payload){
        context.commit('zoomIntegrationTyp', payload)
    }
  },
  mutations: {
    disclaimer: function(state, payload){
        state.disclaimer = payload
    },
    debug: function(state, payload){
      state.debug = payload;
    },
    language: function(state, payload){
      state.language = payload;
    },
    languages: function(state, payload){
      state.languages = payload;
    },
    labels: function(state, payload){
      state.labels = payload;
    },
    api: function(state, payload){
      state.api = payload;
    },
    tiny_api_key: function(state, payload){
      state.tiny_api_key = payload;
    },
    env: function(state, payload){
      state.env = payload;
    },
    user: function(state, payload){
      state.user = payload;
      if(payload===undefined){
        state.signedIn = false
        state.userID = 'guest'
        state.exposeLabels = false
        state.notifications = []
        state.debug = false
      }else{
        if(state.languages.includes(state.user.attributes['custom:language'])){
          state.language = state.user.attributes['custom:language']
        }
        state.signedIn = true
        state.userID = state.user.signInUserSession.accessToken.payload.sub ? state.user.signInUserSession.accessToken.payload.sub : 'guest'
      }
    },
    appVisible: function(state, payload){
      state.appVisible = payload;
    },
    notifications: function(state, payload){
      state.notifications = payload;
    },
    width: function(state, payload){
      state.width = payload;
    },
    height: function(state, payload){
      state.height = payload;
    },
    inactivity: function(state, payload){
      state.inactivity = payload;
    },
    siteBlocker: function(state, payload){
      state.siteBlocker = payload;
    },
    time_zone: function(state, payload){
      state.time_zone = payload;
    },
    exposeLabels: function(state, payload){
      state.exposeLabels = payload;
    },
    zoomIntegrationTyp: function(state, payload){
        state.zoomIntegrationTyp = payload
    }
  },
  modules: {
  }
})
