//Remember to provide component name

import Str from './Str.vue';
import Language from './Language.vue';
import fileUpload from './fileUpload.vue';
import Notifications from './Notifications.vue'

export default [
    Str,
    Language,
    fileUpload,
    Notifications
];