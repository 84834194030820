<template>
  <v-app style="background-color:white;">
    <div @click="$store.commit('inactivity',0)" v-if="$store.getters.siteBlocker===false" :style="{'margin-top': $route.name=='SignIn' ? '0px' : '0px','height':'100%'}">
        <!-- Disclaimer -->
        <v-row class="px-6 py-3" no-gutters justify="space-between" align="center" v-if="$store.getters.disclaimer==='ON'" style="position:relative; top:0; left:0; background-color: #ddecea;">
            <v-col d-flex cols="11">
            <str index='disclaimer.message_content._text'/>
            </v-col>
            <v-col class="d-flex justify-end"><v-icon dense @click="cache.storage.session.set('disclaimer','OFF');$store.commit('disclaimer', 'OFF')">mdi-close</v-icon></v-col>
        </v-row>
      <v-app-bar
        :dark="$store.getters.signedIn || $vuetify.breakpoint.smAndDown"
        :color="headerColor"
        flat
        :app="false"
        :absolute="false"
        :fixed="false"
        :hide-on-scroll="false"
        height="90"
        :class="$vuetify.breakpoint.smAndDown ? 'px-1' : 'px-4'"
        >
        <v-app-bar-nav-icon dark id="nav-bar-icon"
            :style="{'justify-content': $vuetify.breakpoint.width<388 ? 'flex-start' : 'center', 'width':'36px'}"
            @click.stop="drawer=!drawer" v-show="$store.getters.signedIn && $vuetify.breakpoint.smAndDown"></v-app-bar-nav-icon>

        <div style="position:relative; cursor:pointer; font-size: 60px; font-weight: bold; line-height: 40px;" @click="$router.push({name:'Home'})">
          <img alt="Foundation"
            :src="images.logo"
            style="position:relative; max-height: 60px; max-width: 100%; min-width: 130px; padding-left:8px;"/>
        </div>

        <template v-if="$route.name!='SignIn'">

          <v-spacer></v-spacer>

          <div v-if="signedIn && $vuetify.breakpoint.mdAndUp" class="app-bar-item" @click="$router.push({name:'Home'})">
            <Str index="buttons.home._text"/>
          </div>

          <!-- <div class="app-bar-item" @click="$router.push({name:'OnDemand'})">
            <Str index="buttons.on_demand._text"/>
          </div> -->


        </template>
        <v-spacer v-else></v-spacer>

        <template v-if="!signedIn">
            <div class="app-bar-item" @click="$router.push({name:'TechnicalSupport'})">
                <v-icon :style="{color: $route.name=='Verify' || ($route.path=='/login' && $store.getters.width<'960') || ($route.path=='/technicalSupport') ? 'white' : 'rgba(0, 0, 0, 0.54)'}" >mdi-headset</v-icon>
            </div>
        </template>
        <template v-else-if="signedIn && $vuetify.breakpoint.mdAndUp">
            <div class="app-bar-item" @click="$router.push({name:'TechnicalSupport'})">
              <Str index="buttons.technical_support._text"/>
            </div>
        </template>

        <div style="color: white;">
          <Authenticator v-if="$route.name!='SignIn'" @authenticatorReady="authenticatorReady=true" :buttons="{signOut:true}" :badge="true" style="margin: 5px;" :navDrawerSignOut="navDrawerSignOut" @signOutComplete="navDrawerSignOut=false"/>
        </div>

        <div id="language-container" :style="{'color': $route.name=='Verify' || $route.path=='/technicalSupport' ? 'white !important' : 'inherit', 'background-color': $route.path=='/login' && $store.getters.width > '959' ? 'white' : '#55b9ac'}">
          <Language/>
        </div>
      </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            temporary
            app
            dark
            >
            <v-list dense>
                <v-list-item link @click="drawer=false;$router.push({name:'Home'})">
                    <v-list-item-action>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <Str :index="'buttons.home._text'" />
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link @click="$router.push({name:'TechnicalSupport'})">
                    <v-list-item-action>
                        <v-icon>mdi-headset</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <Str :index="'buttons.technical_support._text'"/>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link @click="drawer=false;navDrawerSignOut=true;">
                    <v-list-item-action>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <Str :index="'authenticator.buttons.sign_out'"/>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

      <v-main v-if="ready" class="app-main">
        <Timezone/>
          <Banner v-if="['Events','Event','Evaluation','OnDemand'].includes($route.name)"/>
        <router-view @initLabels="initLabels" @authenticatorReady="authenticatorReady=true"/>
        <Notifications/>
      </v-main>

      <Footer :class="$route.name=='SignIn' ? 'Footer-SignIn' : 'Footer-Standard'"/>
      <v-btn v-if="$store.getters.exposeLabels" @click="initLabels">Init Labels</v-btn>

    </div>
    <siteBlocker/>
  </v-app>
</template>


<script>
import Vue from "vue";
import axios from "axios";
import Banner from '@/components/Banner/Banner'
import Footer from '@/components/Footer/Footer'
import Logo from '@/assets/logo_lh.png'
import Logo_FR from '@/assets/logo_lh_french.png'
import Authenticator from '@/components/Authenticator/Authenticator'
import siteBlocker from '@/components/siteBlocker/siteBlocker'
import Timezone from '@/components/Timezone.vue'
let cache = require('@/plugins/cache.js')

export default {
  name: 'App',
  components: {
    Banner,
    Footer,
    Authenticator,
    siteBlocker,
    Timezone
  },
  created: async function(){
    let self = this
    Vue.prototype.sendRequest = self.sendRequest

      var hidden, visibilityChange;
      if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }
      document.addEventListener(visibilityChange, function (event) {
          if (document[hidden]) {
              self.$store.commit('appVisible',false)
          } else {
              self.$store.commit('appVisible',true)
          }
      });

      window.addEventListener('resize', this.handleResize);

      // this.$store.commit('api',{
      //   local: process.env.VUE_APP_LOCAL_API,
      //   development: process.env.VUE_APP_DEV_API,
      //   staging: process.env.VUE_APP_STAGING_API,
      //   production: process.env.VUE_APP_API,
      // })
      // this.$store.commit('tiny_api_key',process.env.VUE_APP_TINY_API_KEY)

      await this.initLabels()
      this.handleResize()
      this.ready = true

  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  },
  data: function(){
    return {
      drawer: false,
      navDrawerSignOut: false,
      ready: false,
      authenticatorReady: false,
      ui: {
        exposeLabels: false
      },
      session: {
        expires: null,
        time_left: null,
        timer: null,
        inactivity_timer: null
      }
    }
  },
  methods: {
    sendRequest: function(method='get', url, data=null){
      let self = this
      return new Promise((resolve, reject)=>{
        if(self.$store.getters.user){
          axios.defaults.headers['Authorization'] = `Bearer ${self.$store.getters.user.signInUserSession.idToken.jwtToken}`
        }else{
          delete axios.defaults.headers['Authorization']
        }
        if(process.env.NODE_ENV!='development'){
          url = url.replace('/api',process.env.VUE_APP_API)
        }
        axios({
          method,
          url,
          data
        }).then((response)=>{
          if(response.headers['content-type']=="text/calendar; charset=utf-8"){
            let filename = "meeting.ics"
            if(filename){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', filename);
              link.setAttribute('id','download-link')
              document.body.appendChild(link);
              link.click();
              link.remove();
            }

          }
          resolve(response)
        },(error)=>{
          reject(error)
        })
      });
    },
    initLabels: async function(){
      let labels = await this.sendRequest('get','/api/label/downloadAll')
      this.$store.commit('labels',labels.data)
    },
    handleResize: function(){
      this.$store.commit('width', window.innerWidth)
      this.$store.commit('height', window.innerHeight)
    }
  },
  computed: {
    language: function(){
      return this.$store.getters.language
    },
    images: function(){
      return {
        logo: this.language==='fr-ca' ? Logo_FR : Logo
      }
    },
    signedIn: function(){
      return this.$store.getters.signedIn
    },
    debug: function(){
      return this.$store.getters.debug
    },
    headerColor: function(){
        if (this.$route.name=='SignIn' || this.$route.name=='SignUp'){
            if (this.$vuetify.breakpoint.smAndDown){
                return "#16A394"
            }
            else {
                return "white"
            }
        }

        return '';
    }
  },
  watch: {
    signedIn: function(after, before){
        if (after===false){
            cache.storage.session.clear('disclaimer')
            this.$store.commit('disclaimer', 'ON')
        }
    }
  }
};
</script>

<style lang="scss">
.debug{
  font-size: 8pt;
  font-style: italic;
  color: red;
}
.v-app-bar{
  background-color: $secondary !important;
  max-height: 90px;
}
.app-bar-item{
  margin: 15px;
  font-size: 120%;
  cursor: pointer;
  font-weight: bold;
  color: whitesmoke;
  padding-right: 8px;
}
.app-bar-item:hover{
    color: white;
}

.app-main{
  width: 100%;
  margin: 0 auto;
  background-color: white;
}

.Footer-standard{
  background-color: $secondary !important;
  color: white !important;
}

.Footer-SignIn{
  background-color: white !important;
  color: black;
}

pre{
  white-space: pre-wrap;
}

#app{
  max-width: 1920px !important;
  margin: 0 auto;
}

body{
  color: #333333;
  background-color: gray;
}

h3{
    color: $secondary;
}

.v-input--is-label-active .v-input--switch__thumb{
    background-color: $secondary !important;
}

#language-container {
    width: 130px;
    position: initial;
    top: 12px;
    border: 1px solid;
    height: 40px;
    margin-left: 20px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')}{
    #language-container {
        width: 67px;
        min-width: 67px;
    }
}

@media #{map-get($display-breakpoints, 'xs-only')}{
    #language-container {
        margin-left: 4px;
    }
}


</style>
