<template>
        <v-select
        :class="['language-select', {['language-select-mobile']:$vuetify.breakpoint.smAndDown}, $route.name=='Verify' || $route.path=='/technicalSupport' ? 'whiteColour' : '']"
        flat
        dense
        solo
        v-model="lang"
        :items="items"
        :label="'Language'"
        />
</template>

<script>
export default {
    name: "Language",
    created: function(){
        this.init()
    },
    data: function(){
        return {
            lang: null
        }
    },
    methods: {
        init: function(){
            let queryLanguage = this.$route.query.language ? this.$route.query.language : false
            let cookieLanguage = this.cache.storage.local.get('language')
            let browserLanguage = navigator.userLanguage || navigator.language;
            let browserLangPrimaryCode = browserLanguage.toLowerCase().split('-')[0]

            this.lang = queryLanguage ? queryLanguage : cookieLanguage ? cookieLanguage : browserLangPrimaryCode && this.returnMap(this.items,'value') ? `${browserLangPrimaryCode}-ca` : this.language

            let languages = [this.lang]

            for(let i=0; i<this.items.length; i++){
                let item = this.items[i]
                if(item.value!=this.lang){
                    languages.push(item.value)
                }
            }

            this.$store.commit('languages',languages)
        },
        returnMap: function(array, index){
            let output = {}

            for(let i=0; i<array.length; i++){
                let item = array[i]
                output[item[index]] = item
            }


            return output
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        items: function(){
            return [
                {text: this.$vuetify.breakpoint.smAndDown ? 'EN' : 'English', value:'en-ca'},
                {text: this.$vuetify.breakpoint.smAndDown ? 'FR' : 'Français', value:'fr-ca'}
            ]
        }
    },
    watch: {
        language: function(){
            this.lang = this.$store.getters.language
        },
        lang: function(){
            this.cache.storage.local.set('language',this.lang)
            this.$store.commit('language',this.lang)
        }
    }
}
</script>

<style lang="scss">
.theme--light .v-text-field--solo.language-select .v-input__control .v-input__slot{
    background-color: transparent !important;
}

.theme--light .v-text-field--solo.language-select-mobile .v-input__control .v-input__slot{
    padding: 0 3px 0 9px;
}

.language-select-mobile {
    min-width:65px;
    width:65px;
}

.whiteColour.language-select .v-select__selection {
    color: white;
}
.whiteColour.language-select .v-icon {
    color: white !important;
}
</style>
