<template>
<div>
    <v-autocomplete v-if="ready && (!form.timezone || debug)" v-model="form.timezone" :items="timezone_items" :label="'Please select your timezone'"/>
    
    <v-dialog v-model="ui.help" persistent>
        <v-card>
            <v-card-subtitle class="text-h5 grey lighten-2">
                Please indicate your timezone
            </v-card-subtitle>
            <v-card-text>
                <v-autocomplete v-model="form.timezone" :items="timezone_items" :label="'Please select your timezone'"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    :disabled="!form.timezone"
                    text
                    @click="ui.help = false"
                >
                    OK
                </v-btn>                
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    created: function(){
      this.init()
    },
    data: function(){
        return {
            ready: false,
            ui: {
                help: false
            },
            form: {
                timezone: null
            },
            timezones: null
        }
    },
    methods: {
        init: async function(){
            await this.getTimeZones()

            let browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

            for(let i=0; i<this.timezones.length; i++){
                let timezone = this.timezones[i]
                if(timezone.zoneName==browserTimeZone){
                    this.form.timezone = browserTimeZone
                }
            }
            if(!this.form.timezone){
                this.ui.help = true
            }
        },
        getTimeZones: async function(){
            let response
            try {
                response = await this.sendRequest('get','/api/timezone/list')
                this.timezones = response.data
            } catch (error) {
                console.error('Error getting timezones',error)
            }
            this.ready = true
        }
    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
        timezone_items: function(){
            let output = []
            let timezones = this.timezones
            if(timezones){
                for(let i=0; i<timezones.length; i++){
                    let timezone = timezones[i]
                    output.push({
                        text: timezone.zoneName,
                        value: timezone.zoneName
                    })
                }
            }

            return output
        }        
    },
    watch: {
        form: {
            deep: true,
            handler: function(){
                this.$store.commit('time_zone', this.form.timezone)
            }
        }
    }
}

</script>

<style>

</style>