<template>
<div :class="['banner', `${$route.name}`]" :style="{'background-image':background}">
    <template v-if="['Events','Event'].includes($route.name)">
        <img class="d-sm-none d-md-none d-lg-flex image-left" :src="images.bottle_left"/>
        <div class="banner-text px-3">
            <div class="banner-title">
                <str index="banner.title.events._text"/>
            </div>
            <div class="banner-subtitle">
                <str index="banner.subtitle.events._text"/>
            </div>
        </div>
        <img class=".d-sm-none .d-md-flex image-right" :src="images.bottle_right" :style="{'transform': $vuetify.breakpoint.width>1263 ? 'translate(-41%,0%)' : 'translate(-10%,0%)'}"/>
    </template>

    <template v-if="$route.name=='OnDemandFolder'">
        <div class="banner-text">
            <div class="banner-title">
                <str index="banner.title.onDemand._text"/>
            </div>
            <div class="banner-subtitle">
                <str index="banner.subtitle.onDemand._text"/>
            </div>
        </div>
        <img class="d-sm-none d-md-none d-lg-flex image-right" :src="images.onDemand_bottle_right"/>
    </template>

    <template v-if="$route.name=='Evaluation'">
        <div class="banner-text" style="padding: 25px; transform: unset;">
            <div class="banner-title">
                <str index="banner.title.evaluation._text"/>
            </div>
            <div class="banner-subtitle">
                <str index="banner.subtitle.evaluation._text"/>
            </div>
        </div>
        <img class="d-sm-none d-md-none d-lg-flex image-right" :src="images.evaluation_background"/>
    </template>

</div>
</template>

<script>
import bottle_left from "./assets/bottle-left.png"
import bottle_right from "./assets/bottle-right.png"
import onDemand_bg from "./assets/onDemand_bg.png"
import onDemand_bottle_right from "./assets/onDemand_bottle_right.png"
import evaluation_background from "./assets/evaluation_background.png"

export default {
    name: "Banner",
    computed: {
        background: function(){
            if(this.$route.name=='OnDemandFolder'){
                return `url(${this.images.onDemand_bg})`
            }else{
                return
            }
        },
        images: function(){
            return {
                bottle_left,
                bottle_right,
                onDemand_bg,
                onDemand_bottle_right,
                evaluation_background
            }
        }
    }
}
</script>

<style scoped lang="scss">
.banner{
    width: 100%;
    background-color: $primary;
    color: white;
    height: 300px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 40px;
    text-transform: uppercase;
}
.banner-text{
    width: 60%;
    position: relative;
    top: -25px;
    margin-top: 50px;
    text-align: left;
    max-width: 756px;
    transform: translate(-10%,0%);
}

.banner-title{
    font-weight: bold;
    font-size: 35px;
    margin: 0px 0px 15px 0px;
}

.banner-subtitle{
    font-weight: bold;
    line-height: 25px;
    font-size: 14px;
}

img.image-left, img.image-right{
    height: 100%
}

.OnDemandFolder{
    background-color: $background-primary;
}
.Evaluation{
    background-color: #1A9FA2;
}

.OnDemandFolder .banner-text{
    left: 100px;
}

.OnDemandFolder .image-right{
    position: relative;
    right: 200px;
}

@media only screen and (max-width: 800px){
    .banner-title{
        font-size: 20pt;
        margin-bottom: 7px;
    }
    .banner-text{
        width: 100%;
        text-align: center;
    }
    img.image-left, img.image-right{
        display: none;
    }
}

@media only screen and (max-width: 400px){
    .banner-title{
        font-size: 16pt;
        line-height: 32px;
    }
    .banner-subtitle{
        line-height: 23px;
    }
}

@media only screen and (max-width: 323px){
    .banner-subtitle{
        line-height: 21px;
    }
}

@media #{map-get($display-breakpoints, 'md-and-down')}{
    .banner-text {
        transform: unset;
    }
}
</style>
