<template>
<v-menu
    bottom 
    min-width="200px"
    rounded
    offset-y
>
    <template v-slot:activator="{on}">
        <v-btn
            icon
            x-large
            v-on="on"
        >
            <v-avatar
                color="white"
                size="48"
            >
                <h3>
                    {{initials}}
                </h3>
            </v-avatar>

        </v-btn>
    </template>

    <v-card>
        <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
            <h3>{{ user.fullName }}</h3>
            <p class="text-caption mt-1">
            {{ user.email }}
            </p>
            <v-divider class="my-3"></v-divider>
            <slot name="buttons"/>
        </div>
        </v-list-item-content>
    </v-card>    
</v-menu>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    computed: {
        initials: function(){
            let user = this.user
            if((user.given_name && user.given_name.length>1) && (user.family_name && user.family_name.length>1)){
                return `${user.given_name.substr(0,1)}${user.family_name.substr(0,1)}`
            }else{
                return '--'
            }
        }
    }
}
</script>

<style>

</style>