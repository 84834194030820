<template>
    <v-footer class="footer" :class="[{'login':$route.name=='SignIn'}]">
        <v-container class="px-0" style="max-width: 100%;">
            <v-row :no-gutters="true" align-content="center" align="center" justify="center">
                <v-col :cols="12" :md="4"/>
                <v-col :cols="12" :md="4" align-self="center" style="text-align: center;">
                    <str index="footer.all_rights"/>&nbsp;
                    <span style="cursor:pointer;" :class="{'ctcLink_darkgrey': $route.path=='/login', 'ctcLink_white': $route.path!='/login'}" @click="gotoCTCComm()"><str index='footer.ctccomm'/></span>.
                </v-col>
                <v-col :cols="12" :md="4" align-self="center" class="right-col">
                  <div>
                    <v-btn style="padding: 5px;" text dark link @click="popup=true; content='terms'"><str index="footer.terms_of_use.link"/></v-btn>
                    |
                    <v-btn style="padding: 5px;" text dark link @click="popup=true; content='privacy'"><str index="footer.privacy_policy.link"/></v-btn>
                  </div>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="popup" :width="this.$store.getters.width < 768 ? '100%' : '50%'">
            <v-card style='padding:32px 0 16px;'>
                <v-card-title style='word-break:break-word;' class='terms_privacy_headers'>
                    <str v-if="content=='terms'" index="footer.terms_of_use.title"/>
                    <str v-if="content=='privacy'" index="footer.privacy_policy.title"/>
                </v-card-title>
                <v-card-text>
                    <str v-if="content=='terms'" index="footer.terms_of_use.content"/>
                    <str v-if="content=='privacy'" index="footer.privacy_policy.content"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="popup=false">
                        <str index="buttons.close"/>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-footer>
</template>

<script>
export default {
    data: function(){
        return {
            popup: false,
            content: null
        }
    },
    methods: {
        gotoCTCComm: function(){
            return window.open('http://ctccomm.com', '_blank');
        }
    },
    computed: {
        show: function(){
            let groups = this.$store.getters.user && this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] ? this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] : []
            let allow = ['SuperAdmin','staff','developer','tester']
            for(let i=0; i<groups.length; i++){
                let group = groups[i]
                if(allow.includes(group)){
                    return true
                }
            }
            return false
        }
    }
}
</script>

<style scoped lang="scss">
.footer{
    background-color: $secondary;
    height: fit-content;
    color: whitesmoke;
}

.footer.login,
.footer.login .v-btn {
   color: darkgrey;
}

.right-col{
    text-align: right;
}

@media only screen and (max-width: 959px){

    .right-col{
        text-align: center;
        margin-top: 8px;
    }

}
</style>

<style lang='scss'>
.footer {
    .ctcLink_darkgrey {
        a {
            color: darkgrey;
        }
    }
    .ctcLink_white {
        a {
            color: white;
        }
    }
}

.terms_privacy_headers { // in dialog, so not within footer
    font {
        @media(max-width:420px){
            font-size: 32px;
        }
    }
}
</style>
