<template>
<div style="margin: 0 auto;width: 100%;text-align: center;">
    <div class="elevation-0 theme-button-custom">
        <slot name="actionButtons"></slot>
    </div>

    <!-- <template v-if="state!='finished'">
        <template v-if="!enrolled">
            <template v-if="eventData.seats.max.value==0 || bookings<eventData.seats.max.value">
                <v-btn :disabled="state=='finished'" class="elevation-0 theme-primary" @click="$emit('enroll',eventData)">
                    <Str index="events.buttons.enroll" :language="language"/>
                </v-btn>
            </template>

            <v-btn v-else :disabled="true" class="elevation-0 theme-primary">
                Full
            </v-btn>
        </template>
        <template v-else-if="!ui.confirmWithdraw" >
            <v-btn class="elevation-0" color="#1AA323">
                <Str index="events.buttons.enrolled" style="color:white !important;" :language="language"/>
            </v-btn>
        </template>


    </template>
    
    <v-btn v-if="!(options.hide && options.hide.includes('moreDetails'))" class="elevation-0" @click="moreDetails">
        <Str index="events.buttons.details" :language="language"/>        
    </v-btn>

    <div v-if="options && options.bookings && !enrolled && Math.round((bookings/eventData.seats.max.value)*100)>50">
        {{Math.round((bookings/eventData.seats.max.value)*100)}}% booked
        <div v-if="debug" class="debug">
            {{bookings}} of {{eventData.seats.max.value}}
        </div>        
    </div> -->
</div>  
</template>

<script>
export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        // state: {
        //     type: String,
        //     required: false
        // },
        enrolled: {
            type: Boolean,
            required: false,
            default: false
        },
        enrollmentRecord: {
            type: Object,
            required: false
        },
        eventData: {
            type: Object,
            required: true
        },
        buttons: {
            type: Object,
            required: false,
            default: ()=>{return {}}
        },
        language: {
            type: String,
            required: false,
            default: 'en'
        },
        options: {
            type: Object,
            required: false,
            default: ()=>{return {}}
        },
        bookings: {
            type: Number,
            required: true
        }
    },
    data: function(){
        return {
            ui: {
                confirmWithdraw: false
            }
        }        
    },
    methods: {
        allTrue: function(object){
            for(let key in object){
                let result = object[key]
                if(!result){
                    return false
                }
            }
            return true
        },
        moreDetails: function(){
            this.$emit('more_details',this.eventData)
        }        
    }
}
</script>

<style scoped lang="scss">
button {
    border: 1px solid $secondary;
    color: $secondary !important;
    padding: 20px 50px 20px 50px !important;
    margin: 15px 15px 15px 15px;
    width: 160px;
    border-radius: 0px;
}

button:disabled{
    border: none;
}

button.theme-primary {
  background-color: $primary-light-1 !important;
  color: #FCFCFC !important;
}

button.theme-withdraw{
    background-color: $primary !important;
    color: #FCFCFC !important;
}

button.theme-warning{
    background-color: #ff0000 !important;
    color: #FCFCFC !important;
}

button.theme-button-custom{
    background-color: $secondary !important;
    color: #FCFCFC !important;
}
</style>