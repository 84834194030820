import Vue from "vue";
import VueRouter from "vue-router";
import { Auth } from 'aws-amplify';
let cache = require('@/plugins/cache.js')

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      public: false,
      allowed: '*'
    }
  },
  {
    path: '/en',
    name: 'English',
    redirect: '/lang/en',
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/fr',
    name: 'French',
    redirect: '/lang/fr',
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/lang',
    name: 'Language Handler',
    component: () => import('../views/Lang.vue'),
    children: [
      {
        name: 'lang',
        path: '/lang/:code',
        meta: {
          public: true
        }
      }
    ],
    meta: {
      public: true
    }
  },
  {
    path: '/verify',
    name: 'Verify',
    meta: {
      public: true
    },
    component: () => import('../components/Authenticator/verification.vue'),
    children: [
      {
        name: 'Verify',
        path: '/verify/:code',
        meta: {
          public: true
        }
      }
    ]
  },
  {
    path: '/login',
    meta: {
      public: true
    },
    component: () => import('../views/Login.vue'),
    children: [
      {
        name: 'SignIn',
        path: '/login',
        meta: {
          public: true
        }
      },
      {
        name: 'SignUp',
        path: '/signup',
        meta: {
          public: true
        }
      }
    ]
  },
  // {
  //   path: '/signup',
  //   meta: {
  //     public: true
  //   },
  //   component: () => import('../views/Login.vue'),
  //   children: [
  //     {
  //       name: 'SignUp',
  //       path: '/signup',
  //       meta: {
  //         public: true
  //       }
  //     }
  //   ]
  // },
  {
    path: '/restricted',
    name: 'Restricted',
    component: () => import('../views/Restricted.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Events' },
    component: () => import('../views/Home.vue'),
    meta: {
      public: false,
      allowed: '*'
    }
  },
  {
    path: '/technicalSupport',
    name: 'TechnicalSupport',
    component: () => import('../views/TechnicalSupport.vue'),
    meta: {
        public: true,
        allowed: '*'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      public: false,
      allowed: '*'
    }
  },
  {
    path: '/events',
    meta: {
      public: false,
      allowed: ['*']
    },
    component: () => import('../views/EventView.vue'),
    children: [
      {
        path: '/events/:language',
        name: 'Events',
        meta: {
          public: false,
          allowed: ['*']
        }
      },
      {
        path: '/events/:language/:event_id',
        name: 'Event',
        meta: {
          public: false,
          allowed: ['*']
        }
      }
    ]
  },
  {
    path: '/staff',
    name: 'Staff',
    component: () => import('../views/Staff.vue'),
    meta: {
      public: false,
      allowed: ['staff','developer','SuperAdmin']
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: {
      public: false,
      allowed: ['SuperAdmin']
    }
  },
  {
    path: '/eventAdmin',
    name: 'eventAdmin',
    component: () => import('../views/EventAdmin.vue'),
    meta: {
      public: false,
      allowed: ['staff','developer','SuperAdmin','hygienist']
    }
  },
  {
    path: '/labelAdmin',
    component: () => import('../views/LabelAdmin.vue'),
    meta: {
      public: false,
      allowed: ['staff','developer','SuperAdmin','tester']
    },
    children: [
      {
        path: '/labelAdmin/edit/:index',
        name: 'editLabel',
        meta: {
          public: false,
          allowed: ['staff','developer','SuperAdmin','tester']
        }
      },
      {
        path: '/labelAdmin/new',
        name: 'newLabel',
        meta: {
          public: false,
          allowed: ['staff','developer','SuperAdmin','tester']
        }
      }
    ]
  },
  {
    path: '/questions',
    name: 'Questions',
    component: () => import('../views/Questions.vue'),
    meta: {
      public: false,
      allowed: ['developer','SuperAdmin']
    },
    children: [
      {
        path: '/questions/:questionSet',
        name: 'questionSet',
        meta: {
          public: false,
          allowed: ['*']
        }
      }
    ]
  },
  {
    path: '/evaluation',
    component: () => import('../views/Evaluation.vue'),
    path: '/evaluation/:event_id/:question_set_id',
    name: 'Evaluation',
    meta: {
      public: false,
      allowed: ['*']
    }
  },
  {
    path: '/evaluationOnDemand',
    component: () => import('../views/Evaluation.vue'),
    path: '/evaluationOnDemand/:event_id/:question_set_id',
    name: 'EvaluationOnDemand',
    meta: {
      public: false,
      allowed: ['*'],
    }
  },
  {
    path: '/pxp',
    component: () => import('../views/PXP.vue'),
    children: [
      {
        path: '/pxp',
        name: 'PXP',
        meta: {
          public: false,
          allowed: ['*']
        }
      }
    ]
  },
  {
    path: '/library',
    component: () => import('../views/Library.vue'),
    children: [
      {
        path: '/library',
        name: 'Library',
        meta: {
          public: false,
          allowed: ['*']
        }
      }
    ]
  },
  {
    path: '/OnDemand',
    component: () => import('../views/OnDemand.vue'),
    children: [
      {
        name: 'OnDemand',
        path: '/OnDemand',
        meta: {
          public: false,
          allowed: ['*']
        },
        children: [
          {
            name: 'OnDemandLanguage',
            path: '/OnDemand/:language',
            meta: {
              public: false,
              allowed: ['*']
            },
            children: [
              {
                name: 'OnDemandFolder',
                path: '/OnDemand/:language/:folder',
                meta: {
                  public: false,
                  allowed: ['*']
                },
                children: [
                  {
                    name: 'OnDemandItem',
                    path: '/OnDemand/:language/:folder/:id',
                    meta: {
                      public: false,
                      allowed: ['*']
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/dashboard',
    component: () => import('../views/Dashboard.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          public: false,
          allowed: ['developer', 'SuperAdmin', 'tester', 'staff', 'client']
        }
      }
    ]
  },
  {
    path: '/patientAssessment',
    name: 'PatientAssessment',
    component: () => import('../views/PatientAssessment.vue'),
    meta: {
      public: false,
      allowed: ['*']
    }
  },
  {
    path: '/questionSetEditor',
    name: 'questionSetEditor',
    component: () => import('../views/QuestionSetEditor.vue'),
    meta: {
      public: false,
      allowed: ['developer','SuperAdmin']
    },
    children: [
      {
        path: '/questionSetEditor/:questionSet',
        meta: {
          public: false,
          allowed: ['developer','SuperAdmin']
        }
      }
    ]
  },
  {
    path: '/videos',
    meta: {
      public: false,
      allowed: ['*']
    },
    component: () => import('../views/Videos.vue'),
    children: [
      {
        path: '/videos/:video_id',
        name: 'Videos',
        meta: {
          public: false,
          allowed: ['*']
        }
      }
    ]
  },
  {
    path: '/dataDump',
    component: () => import('../views/DataDump.vue'),
    children: [
      {
        path: '/dataDump',
        name: 'DataDump',
        meta: {
          public: false,
          allowed: ['SuperAdmin','staff', 'developer', 'tester', 'hygienist']
        }
      }
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next)=>{
  if(to.meta.public){
    next()
  }else{
    let findUser = async function(){
      try{
        const user = await Auth.currentAuthenticatedUser()
        let groups = user.signInUserSession.accessToken.payload['cognito:groups']
        let allow = to.meta.allowed.includes('*')

        if(typeof groups=='object' && groups.length!=undefined){
          for(let i=0; i<groups.length; i++){
            let group = groups[i]
            if( to.meta.allowed.includes(group) ){
              allow = true
              break;
            }
          }
        }

        if(allow){
          next()
        }else{
          next('/restricted')
        }
      }catch(err){
        cache.storage.local.set('pathAfterLogin',to.fullPath)
        next('/login')
      }
    }

    findUser()
  }

})

export default router;
