<template>
<div v-if="notifications.length>0 && signedIn">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-x
    >
      <!-- :nudge-width="200" -->
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab v-bind="attrs" v-on="on" :fixed="true" dark :bottom="true" :right="true" class="elevation-0">
            <v-icon>mdi-bell-outline</v-icon>
        </v-btn>        
      </template>

      <v-card style="z-index: 5;">
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
                <v-icon>mdi-robot-confused-outline</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Notifications</v-list-item-title>
              <!-- <v-list-item-subtitle>Founder of Vuetify</v-list-item-subtitle> -->
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                dark
                small
                icon
                @click="$store.commit('notifications',[])"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item v-for="(notification, index) in notifications" :key="'notification_'+index">
            <v-list-item-action>
                <v-btn dark icon @click="notification.doThis(notification.eventData)">
                    <v-icon v-if="notification.icon && notification.icon.type=='icon'">
                      {{notification.icon.value ? notification.icon.value : 'mdi-google-downasaur'}}
                    </v-icon>
                </v-btn>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{notification.label}}</v-list-item-title>
                <!-- <EventCard :enrolled="true" :program="notification.program" :eventData="notification.eventData" class="events-card"/> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="menu = false"
          >
            Save
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-menu>    

</div>
</template>

<script>
import EventCard from '@/components/Events/EventCard'
export default {
    name: 'Notifications',
    components: {
        EventCard
    },
    data: function(){
        return {
            fav: true,
            menu: false,
            message: false,
            hints: true
        }
    },
    computed: {
        notifications: function(){
            return this.$store.getters.notifications
        },
        signedIn: function(){
          return this.$store.getters.signedIn
        }
    },
    watch: {
        notifications: function(){
            let notifications = this.notifications
            let output = {}
            for(let i=0; i<notifications.length; i++){
                let notification = notifications[i]
                if(!output[notification.type]){
                    output[notification.type] = {}
                }
                if(!output[notification.type][notification.id]){
                    output[notification.type][notification.id] = 0
                }
                output[notification.type][notification.id]++

                if(output[notification.type][notification.id]>1){
                    this.$store.getters.notifications.splice(i,1)
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.v-btn{
    background-color: $primary-light-1 !important;
}
</style>