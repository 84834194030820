<template>
  <div>
    <v-form ref="moreInfo" v-model="validForms.moreInfo">
        <template v-for="(field, field_index) in fields">

            <v-text-field v-if="field.type=='textfield'" :key="`field_${field_index}`" outlined v-model="model[field.target]" 
                :required="field.required"
                :rules="field.rules"
            >
                <template v-slot:label>
                    <str :index="field.label"/>
                </template>
            </v-text-field>

            <v-autocomplete v-if="field.type=='autocomplete'" :key="`field_${field_index}`" v-model="model[field.target]" 
                :items="field.items" 
                :required="field.required"
                :rules="field.rules"
                background-color="white"
                autocomplete="disable_autocomplete"
                dense
                outlined
            >
                <template v-slot:label>
                    <str :index="field.label" />
                </template>                    
            </v-autocomplete>

            <v-select v-if="field.type=='select'" :key="`field_${field_index}`" v-model="model[field.target]"
                :items="field.items" 
                :required="field.required"
                :rules="field.rules"
                background-color="white"
                dense
                outlined
            >
                <template v-slot:label>
                    <str :index="field.label" />
                </template>    

            </v-select>

            <v-checkbox v-if="field.type=='casl'" :true-value="true" :false-value="false" :key="`field_${field_index}`" v-model="model[field.target]"
                :items="field.items" 
                background-color="white"
                dense
                outlined
                style="position: relative; top: -20px;"
            >
                <template v-slot:label>
                    <str :index="field.label" style="position: relative; top: 20px;"/>
                </template>    

            </v-checkbox>

        </template>

        <v-btn :disabled="!validForms.moreInfo" @click="$emit('updateMoreInfo',model)">
            <str index="buttons.submit"/>
        </v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
    props: {
        validations: {
            type: Object,
            required: true
        },
        rules: {
            type: Object,
            required: true
        },
        labels: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
    },
    created: function(){
        this.init()
    },
    data: function(){
        return {
            validForms: {
                moreInfo: false
            },
            db: {
                countries: [],
                states: [],
                cities: []
            }
        }
    },
    methods: {
        init: async function(){
            this.getCountries()
            this.getStates()
            this.getCities()
        },
        getCountries: async function(){
            async function getCountries(){
                let response
                try {
                    response = await self.sendRequest('get','/api/csc/getCountries')    
                } catch (error) {
                    console.error('getCountries error:', error)
                }
                return response.status==200 ? response.data : []
            }

            let self = this

            let getCountriesResponse = await getCountries()
            self.db.countries = getCountriesResponse
        },
        getStates: async function(){
            async function getData(country){
                let response
                try {
                    response = await self.sendRequest('get',`/api/csc/getStates/${country}`)
                } catch (error) {
                    console.error('getStates error', error)                    
                }
                return response.status==200 ? response.data : []
            }

            let self = this
            let country = self.model.country
            if(typeof country=='string' && country.length>0){
                self.db.states = await getData(country)
            }else{
                console.error('getStates error: missing required data', country)
            }
        },
        getCities: async function(){
            async function getData(country, prov_state){
                let response
                try {
                    response = await self.sendRequest('get',`/api/csc/getCities/${country}/${prov_state}`)
                } catch (error) {
                    console.error('getCities error', error)                    
                }
                return response.status==200 ? response.data : []
            }

            let self = this
            let country = self.model.country
            let prov_state = self.model.prov_state
            if(((typeof country=='string' && country.length>0)) && (typeof prov_state=='string' && prov_state.length>0)){
                self.db.cities = await getData(country, prov_state)
            }else{
                console.error('getCities error: missing required data', {country, prov_state})
            }
        },
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        fields: function(){
            let output = [
                {
                    target: 'given_name',
                    label: 'forms.given_name',
                    required: true,
                    rules: this.rules.required,
                    type: 'textfield'
                },
                {
                    target: 'family_name',
                    label: 'forms.family_name',
                    required: true,
                    rules: this.rules.required,
                    type: 'textfield'
                },
                {
                    target: 'clinic',
                    label: 'forms.clinic',
                    required: false,
                    rules: [],
                    type: 'textfield'
                },
                {
                    target: 'country',
                    label: 'forms.country',
                    required: false,
                    rules: [],
                    type: 'autocomplete',
                    items: this.countries
                },
                {
                    target: 'prov_state',
                    label: 'forms.province',
                    required: false,
                    rules: [],
                    type: 'autocomplete',
                    items: this.states
                },
                {
                    target: 'city',
                    label: 'forms.city',
                    required: false,
                    rules: [],
                    type: 'autocomplete',
                    items: this.cities
                },
                {
                    target: 'address',
                    label: 'forms.address',
                    required: false,
                    rules: [],
                    type: 'textfield'
                },
                {
                    target: 'postal_zip',
                    label: 'forms.postal_code',
                    required: false,
                    rules: [],
                    type: 'textfield'
                },
                {
                    target: 'profession',
                    label: 'forms.field.profession',
                    required: true,
                    rules: this.rules.required,
                    type: 'select',
                    items: this.professions
                },
                // {
                //     target: 'ctc_casl',
                //     label: 'forms.casl',
                //     required: false,
                //     rules: [],
                //     type: 'casl'
                // }
            ]

            return output
        },
        countries: function(){
            let list = []
            let countries = this.db.countries
            for(let i=0; i<countries.length; i++){
                let country = countries[i]
                list.push({
                    text: country.name,
                    value: country.code
                })
            }
            return list
        },
        csc: function(){
            return {
                country: this.model.country,
                prov_state: this.model.prov_state,
                city: this.model.city
            }
        },
        states: function(){
            let list = []
            let states = this.db.states
            for(let i=0; i<states.length; i++){
                let state = states[i]
                list.push({
                    text: state.name,
                    value: state.code
                })
            }
            return list
        },
        cities: function(){
            let list = []
            let cities = this.db.cities
            for(let i=0; i<cities.length; i++){
                let city = cities[i]
                list.push({
                    text: city.name,
                    value: city.name
                })
            }
            list.push({
                text: this.$store.getters.language=='fr-ca' ? 'Non listé' : 'Not listed',
                value: 'not_listed'
            })

            return list
        },
        professions: function(){
            let labels = this.labels
            let language = this.language

            let list = [
                {
                    text: labels?.forms?.profession?.dentist?._text[language] ? labels.forms.profession.dentist._text[language] : 'MISSING - labels.forms.profession.dentist[language]',
                    value: 'dentist'
                },
                {
                    text: labels?.forms?.profession?.dental_hygienist?._text[language] ? labels.forms.profession.dental_hygienist._text[language] : 'MISSING - labels.forms.profession.dental_hygienist[language]',
                    value: 'dental_hygienist'
                },
                {
                    text: labels?.forms?.profession?.periodontist?._text[language] ? labels.forms.profession.periodontist._text[language] : 'MISSING - labels.forms.profession.periodontist[language]',
                    value: 'periodontist'
                },
                {
                    text: labels?.forms?.profession?.orthodontist?._text[language] ? labels.forms.profession.orthodontist._text[language] : 'MISSING - labels.forms.profession.orthodontist[language]',
                    value: 'orthodontist'
                },
                {
                    text: labels?.forms?.profession?.other?._text[language] ? labels.forms.profession.other._text[language] : 'MISSING - labels.forms.profession.other[language]',
                    value: 'other'
                },
            ]


            return list
        }
    },
    watch: {
        csc: {
            deep: true,
            handler: function(after, before){
                if(after.country!=before.country){
                    this.getStates()
                }
                if(after.prov_state!=before.prov_state){
                    this.getCities()
                }
            }
        }
    }
}
</script>

<style>

</style>