module.exports = {
    cookie: {
        set: function(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
    
        get: function(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return false;
        },
        clear: function(cname){
            document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
        }
    },
    storage: {
        session: {
            set: function(key, data){
                let storage = window.sessionStorage;

                if(storage){                    
                    try{
                        storage.setItem(key,JSON.stringify(data));
                        return true
                    }catch(error){
                        console.error('storage > set error', error)
                        return false
                    }
                }

                return false
            },
            get: function(key){
                let storage = window.sessionStorage;
                let cache = storage.getItem(key);
                if(cache){
                    cache = JSON.parse(cache);
                    return cache
                }
                return null
            },
            clear: function(key){
                let storage = window.sessionStorage;
                if(storage){
                    try{
                        storage.removeItem(key)
                        return true
                    }catch(error){
                        console.error('storage > clear error',error)
                        return false
                    }
                }
                return false
            }            
        },
        local: {
            set: function(key, data){
                let storage = window.localStorage;

                if(storage){                    
                    try{
                        storage.setItem(key,JSON.stringify(data));
                        return true
                    }catch(error){
                        console.error('storage > set error', error)
                        return false
                    }
                }

                return false
            },
            get: function(key){
                let storage = window.localStorage;
                let cache = storage.getItem(key);
                if(cache){
                    cache = JSON.parse(cache);
                    return cache
                }
                return null
            },
            clear: function(key){
                let storage = window.localStorage;
                if(storage){
                    try{
                        storage.removeItem(key)
                        return true
                    }catch(error){
                        console.error('storage > clear error',error)
                        return false
                    }
                }
                return false
            }
        }
    }
}