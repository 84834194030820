<template>
<div v-if="enrolled && $store.getters.user">
    <h4 align='center' v-if="eventData.state=='finished' && eventData.evaluation && !missingEval">Evaluation Received - Thanks</h4>

    <!-- <template v-if="eventData.state!='finished'">
        <v-btn v-if="eventData.zoom" link :href="eventData.zoom.join_url" target="_blank" class="elevation-0" :disabled="!['started','minutes_away'].includes(eventData.state)">
            <v-icon left>mdi-link</v-icon>
            Join
        </v-btn>
        <v-btn icon :href="`/api/event/ics/${eventData.id}/${$store.getters.user.signInUserSession.idToken.jwtToken}`" target="_blank" class="elevation-0">
            <v-icon left>mdi-calendar-export</v-icon> ICS
        </v-btn>

        <v-btn v-if="!ui.confirmWithdraw" icon :disabled="eventData.state=='finished'" class="elevation-0" @click="ui.confirmWithdraw = true">
            <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
        <template v-else>
            <v-btn class="theme-warning" @click="ui.confirmWithdraw=false; $emit('withdraw',eventData)">
                <Str index="buttons.confirm" :language="language"/>
            </v-btn>
            <v-btn class="theme-withdraw" @click="ui.confirmWithdraw=false">
                <Str index="buttons.cancel" :language="language"/>
            </v-btn>
        </template>        
    </template> -->

    <div class="elevation-0 theme-button-custom">
        <slot name="resourceButtons"></slot>
    </div>
    
    <!-- <template v-for="(customButton, i) in buttons">
        <v-btn v-if="allTrue(customButton.rules)" @click="customButton.doThis(eventData)" class="elevation-0 theme-button-custom" :key="'customButton_'+i">{{customButton.label[language]}}</v-btn>
    </template>   -->
</div>
</template>

<script>
export default {
    props: {
        enrolled: {
            type: Boolean,
            required: false,
            default: false
        },
        enrollmentRecord: {
            type: Object,
            required: false
        },
        eventData: {
            type: Object,
            required: true
        },
        missingEval: {
            type: Boolean,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        buttons: {
            type: Object,
            required: false,
            default: ()=>{return {}}
        }
    },
    data: function(){
        return {
            ui: {
                confirmWithdraw: false
            }
        }
    },
    methods: {
        allTrue: function(object){
            for(let key in object){
                let result = object[key]
                if(!result){
                    return false
                }
            }
            return true
        }
    }
}
</script>

<style scoped lang="scss">
.v-btn {
    // padding: 15px 25px 15px 25px !important;
    margin: 10px 10px 10px 10px;
    width: 100px auto;
    border-radius: 0px;
    font-size: 9pt;
}
.v-btn {
    border: 1px solid $secondary;
    color: $secondary;
}
.v-btn:disabled{
    border: none;
}

.v-btn.theme-primary {
  background-color: $secondary !important;
  color: #FCFCFC !important;
}

.v-btn.theme-withdraw{
    background-color: $primary !important;
    color: #FCFCFC !important;
}

.v-btn.theme-warning{
    background-color: #ff0000 !important;
    color: #FCFCFC !important;
}

.theme-button-custom .v-btn{
    padding: 10px 10px 10px 10px !important;
    // background-color: $secondary !important;
    // color: #FCFCFC !important;
}

// button.theme-button-custom{
//     padding: 20px 40px 20px 40px !important;
//     background-color: $secondary !important;
//     color: #FCFCFC !important;
//     width: 130px;
// }
</style>